import React, { useEffect, useState } from "react";
import FilterScreen from "./FilterScreen";
import ShopKeeperTable from "./ShopKeeperTable";
import "./ShopKeeper.css";

import { getApiData } from "../../api/apiService";
import { Spin, message } from "antd";
import useLogout from "../../utils/authUtils";

const ShopKeeper = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [filter, setFilter] = useState("All");
  const [activeCount, setActiveCount] = useState(0);
  const [inactiveCount, setInactiveCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [shopkeeperData, setShopKeeperData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const logout = useLogout();

  const fetchShopKeepersList = async () => {
    const endpoint = "getListofShopKeeprs";
    try {
      setLoading(true);
      const { data, loading: postLoading } = await getApiData(endpoint, true);
      console.log("Shopkeeper List fetched successful:", data);
      setLoading(postLoading);
      if (data.responseCode === 200) {
        console.log(data.message);
        setShopKeeperData(data?.data);
        const activeShopkeeper = data?.data.filter(
          (Shopkeeper) => Shopkeeper.status === "active"
        );
        const inactiveShopkeeper = data?.data.filter(
          (Shopkeeper) => Shopkeeper.status === "inactive"
        );
        setTotalCount(data?.data?.length);
        setActiveCount(activeShopkeeper.length);
        setInactiveCount(inactiveShopkeeper.length);
      } else if (data.responseCode === 400) {
        console.log(data);
        console.log(data.message);
      } else if (data.responseCode === 401) {
        message.error(data.message);
        logout();
      }
    } catch (error) {
      console.log("Error fetching Shopkeepr list:", error);
      message.error(error?.error?.error?.message);
      setLoading(false);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchShopKeepersList();
  }, []);

  const filterShopkeepersBySearchInput = (shopkeepers, searchInput) => {
    if (!searchInput) return shopkeepers;
    const lowerCaseSearchInput = searchInput.toLowerCase();
    return shopkeepers.filter(
      (shopkeeper) =>
        shopkeeper?.name?.toLowerCase()?.includes(lowerCaseSearchInput) ||
        shopkeeper?.shopName?.toLowerCase()?.includes(lowerCaseSearchInput) ||
        shopkeeper?.email?.toLowerCase()?.includes(lowerCaseSearchInput) ||
        shopkeeper?.contactNumber?.includes(searchInput) ||
        shopkeeper?.address?.toLowerCase()?.includes(lowerCaseSearchInput)
    );
  };

  const filteredShopkeeper = filterShopkeepersBySearchInput(
    shopkeeperData?.filter((Shopkeeper) => {
      if (filter === "All") {
        return true;
      } else if (filter === "Active") {
        return Shopkeeper?.status === "active";
      } else if (filter === "Inactive") {
        return Shopkeeper?.status === "inactive";
      }
    }),
    searchInput
  );

  useEffect(() => {
    const activeShopkeeper = filteredShopkeeper?.filter(
      (Shopkeeper) => Shopkeeper.status === "active"
    );
    const inactiveShopkeeper = filteredShopkeeper?.filter(
      (Shopkeeper) => Shopkeeper.status === "inactive"
    );
    setTotalCount(filteredShopkeeper?.length);
    setActiveCount(activeShopkeeper.length);
    setInactiveCount(inactiveShopkeeper.length);
  }, [searchInput]);
  console.log(shopkeeperData);

  return (
    <Spin spinning={loading}>
      <div className='Shopkeeper-container'>
        <div className='Shopkeeper-content'>
          <FilterScreen
            shopkeeperData={filteredShopkeeper}
            fetchShopKeepersList={fetchShopKeepersList}
            filter={filter}
            setFilter={setFilter}
            activeCount={activeCount}
            inactiveCount={inactiveCount}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            totalCount={totalCount}
          />
          <ShopKeeperTable
            data={filteredShopkeeper}
            fetchShopKeepersList={fetchShopKeepersList}
          />
        </div>
      </div>
    </Spin>
  );
};
// ShopKeeper
export default ShopKeeper;
